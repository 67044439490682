import { isAnyRealEstateCategory } from '@kijiji/category'
import {
  type AttributeFilter,
  type AttributeValue,
  type FilterGroup,
} from '@kijiji/generated/graphql-types'
import { type TFunction } from 'next-i18next'

import { type ApiLocale, API_LOCALE } from '@/domain/locale'

export type RealEstateAttributes = {
  nearestIntersection?: string
  bathrooms?: string
  bedrooms?: string
  unitType?: string
  parkingSpots?: string
  squareFootage?: string
  petsAllowed?: boolean
  rentalBadge?: string
  virtualTour?: boolean
  additionalOptions?: string[]
}

type NearestIntersectionArray = string[] | undefined | null

export const ATTRIBUTES = {
  BATHROOMS: 'numberbathrooms',
  BEDROOMS: 'numberbedrooms',
  UNIT_TYPE: 'unittype',
  PARKING_SPOTS: 'numberparkingspots',
  SQUARE_FOOTAGE: 'areainfeet',
  PETS_ALLOWED: 'petsallowed',
  RENTAL_BADGE: 'rentalBadge',
  RENTAL_ADDITIONAL_OPTIONS: 'rentalsvirtualoptions',
  RENTALS_VIRTUAL_OPTIONS: 'rentalsvirtualoptions',
}

const VALID_UNIT_TYPES = [
  'apartment',
  'condo',
  'basement-apartment',
  'house',
  'townhouse',
  'duplex-triplex',
]

/**
 * Returns real estate only attributes related to the listing:
 * - Nearest intersection
 * - Number of bathrooms
 * - Number of bedrooms
 * - Unit type, eg condo, house, apartment, etc
 * - Number of parking spots
 * - Unit area in square feet
 * - Whether or not pets are allowed
 * - Rental badge as a string to map to a translation key
 * */
export const getRealEstateAttributes = (
  attributesDictionary: Record<string, string[]>,
  nearestIntersectionArray: NearestIntersectionArray
): RealEstateAttributes => {
  /** General method for normalizing null attributes */
  const normalizeNullAttributes = (attribute?: string) => {
    if (!attribute || attribute === 'Not Available') return undefined
    return attribute
  }
  /**
   * Usually null (landlord does not have to provide)
   * Should be an array with two street neames, eg ['Bloor Street', 'Younge Street']
   */
  const nearestIntersection = nearestIntersectionArray
    ? nearestIntersectionArray.join(' / ')
    : undefined

  /**
   * Bathroom comes with 2 digits we have to divide by 10 to get the correct value to be displayed
   * Ex: 15 to 1.5 / 30 to 3
   *  */
  const parsedBathrooms = normalizeNullAttributes(attributesDictionary[ATTRIBUTES.BATHROOMS]?.[0])
  const bathrooms = parsedBathrooms ? (parseInt(parsedBathrooms) / 10).toString() : undefined

  /** Number of bedrooms on listing */
  const bedrooms = normalizeNullAttributes(attributesDictionary[ATTRIBUTES.BEDROOMS]?.[0])

  /** Rental unit type, eg apartment, condo, house. Only valid unit types should be returned as
   * we only have translations for 'apartment', 'condo', 'basement', 'house', 'townhouse' and 'duplex/triplex'.
   */
  const parsedUnitType = normalizeNullAttributes(attributesDictionary[ATTRIBUTES.UNIT_TYPE]?.[0])

  const unitType =
    parsedUnitType && VALID_UNIT_TYPES.includes(parsedUnitType) ? parsedUnitType : undefined

  /** Number of parking spots */
  const parkingSpots = normalizeNullAttributes(attributesDictionary[ATTRIBUTES.PARKING_SPOTS]?.[0])

  /**
   * Rental unit area in square feet, some landlords intentionally set to 0 or 1
   * Sometimes undefined is returned, even though, technically the value should always exist.
   * */
  const parsedSquareFootage = normalizeNullAttributes(
    attributesDictionary[ATTRIBUTES.SQUARE_FOOTAGE]?.[0]
  )
  const squareFootage =
    parsedSquareFootage && parseInt(parsedSquareFootage) > 1 ? parsedSquareFootage : undefined

  /**
   * petsallowed comes back as 0, 1 or limited (all are strings).
   * We return false for 0 or limited.
   * */
  const petsAllowed = attributesDictionary[ATTRIBUTES.PETS_ALLOWED]?.[0] === '1'

  return {
    nearestIntersection,
    bathrooms,
    bedrooms,
    unitType,
    parkingSpots,
    squareFootage,
    petsAllowed,
  }
}

export const getRealEstateBadges = (
  attributesDictionary: Record<string, string[]>,
  t: TFunction
) => {
  /**
   * Rental badge
   * It will get a rental badge label that should be shown in the card if exists
   * This is a web-only paid feature
   * */
  const rentalBadge = attributesDictionary[ATTRIBUTES.RENTAL_BADGE]?.[0]
  const rentalBadgeText = rentalBadge ? t(`listing.attributes.rental_badge.${rentalBadge}`) : ''

  /** Virtual tour */
  const virtualTour =
    attributesDictionary[ATTRIBUTES.RENTALS_VIRTUAL_OPTIONS]?.indexOf('virtualtour') > -1

  return { rentalBadgeText, virtualTour }
}

export const getRealEstateAdditionalOptions = (
  attributesDictionary: Record<string, string[]>,
  t: TFunction
) => {
  /**
   * Additional options
   * Should only add "virtualtour" or "videowalkthrough"
   * Both will have the same translation - Should show only once
   * */
  const rentalAdditionalOptions = attributesDictionary[ATTRIBUTES.RENTAL_ADDITIONAL_OPTIONS] || []
  let additionalOptions = [...rentalAdditionalOptions]

  if (additionalOptions.includes('virtualtour') && additionalOptions.includes('videowalkthrough')) {
    additionalOptions = additionalOptions.filter((item) => item !== 'virtualtour')
  }

  const additionalOptionsText = additionalOptions.map((item) =>
    t(`listing.additional_options.options.${item}`)
  )

  return additionalOptionsText
}

export const getRealEstateListingAttributes = (
  attributesDictionary: Record<string, string[]>,
  nearestIntersection: NearestIntersectionArray,
  categoryId: number,
  t: TFunction
) => {
  // Early return if it is not RE category
  if (!isAnyRealEstateCategory(categoryId)) {
    return { realEstateAttributes: {} }
  }

  // Get the RE attributes in listing if any
  const realEstateAttributes = getRealEstateAttributes(attributesDictionary, nearestIntersection)

  const { rentalBadgeText, virtualTour } = getRealEstateBadges(attributesDictionary, t)

  const additionalOptions = getRealEstateAdditionalOptions(attributesDictionary, t)

  return {
    additionalOptions,
    realEstateAttributes,
    rentalBadge: rentalBadgeText,
    virtualTour,
  }
}

/** Get the attribute filters from the controls for a specific attribute */
export const getAttributeFilterLabel = (
  attributeName: string,
  attributeValue: string,
  attributeFilter: FilterGroup[]
): string => {
  const matchedAttribute = attributeFilter.find((attribute) =>
    attribute.filters.some((filter) => filter.name === attributeName)
  )

  const attributeFilters: AttributeFilter | undefined = matchedAttribute?.filters[0]

  const attribute: AttributeValue | undefined = attributeFilters?.values?.find(({ value }) => {
    if (parseInt(value, 10) >= 10) {
      return (parseInt(value) / 10).toString() === attributeValue
    }
    return value === attributeValue
  })

  return attribute?.label ?? ''
}

/** Get the real estate listing card title */
export const getRealEstateListingCardTitle = (
  realEstateAttributes: RealEstateAttributes,
  apiLocale: ApiLocale,
  t: TFunction
) => {
  const { squareFootage = '', bedrooms = '', bathrooms = '' } = realEstateAttributes

  // Parse values safely
  const parsedBedrooms = parseFloat(bedrooms)
  const parsedBathrooms = parseFloat(bathrooms)
  const parsedSquareFootage = parseInt(squareFootage, 10)

  const labels = { bedroomLabel: '', bathroomLabel: '', squareFootageLabel: '' }

  const isHalf = parsedBedrooms % 1 !== 0 // Check if is a fractional number
  const count = Math.floor(parsedBedrooms) // Get the Int count

  if (parsedBedrooms === 0) {
    labels.bedroomLabel = t(`listing.attributes.bed_number`, {
      count: parsedBedrooms,
    })
  } else if (parsedBedrooms) {
    labels.bedroomLabel = t(`listing.attributes.bed_number${isHalf ? '_den' : ''}`, {
      count: apiLocale === API_LOCALE.fr_CA ? count + 2 : count,
    })
  }

  if (parsedBathrooms) {
    labels.bathroomLabel = t('listing.attributes.bath_number', {
      count: parsedBathrooms,
    })
  }

  // For square footage, we only want to show the label if the value is greater than 1
  if (parsedSquareFootage) {
    labels.squareFootageLabel = t('listing.attributes.sqft.size.label', {
      size: parsedSquareFootage,
    })
  }

  // Construct the final formatted title
  return Object.values(labels).filter(Boolean).join(' • ')
}
